/** @jsxImportSource theme-ui */
import React, { Component } from 'react';
import circuitImage from '../../images/circuit.png';

interface Props {
  children: React.ReactNode;
}

interface State {
  imageIsLoaded: boolean;
}

class Background extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { imageIsLoaded: false };
  }

  componentDidMount() {
    const image = new Image();
    image.src = circuitImage;
    image.onload = () => {
      this.setState({ imageIsLoaded: true });
    };
  }

  render() {
    const color = this.state.imageIsLoaded ? '#433FD1' : '#000024';
    const img = this.state.imageIsLoaded ? `, url(${circuitImage})` : '';
    const backgroundStyle = `linear-gradient(90deg, #050510 0%, rgba(196, 196, 196, 0) 50%), linear-gradient(144.37deg, #060611 13.5%, ${color} 48.44%)${img}`;

    return (
      <div
        sx={{
          zIndex: '-1',
          position: 'absolute',
          width: 'calc(100vw - (100vw - 100%))',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'background',
          backgroundSize: 'cover',
        }}
      >
        <div
          sx={{
            zIndex: '-1',
            right: '0',
            width: '50vw',
            height: '100%',
            position: 'absolute',
            background: backgroundStyle,
            backgroundSize: 'cover',
            backgroundBlendMode: 'normal, multiply',
            backgroundPosition: '60%',
          }}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Background;
