export const theme = {
  breakpoints: ['40em', '64em'],
  fonts: {
    body: 'GT America',
    heading: 'GT America',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [
    '0.875rem',
    '1rem',
    '1.125rem',
    '1.5rem',
    '2rem',
    '2.25rem',
    '2.5rem',
    '4rem',
    '4.5rem',
    '5.5rem',
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacing: {},
  colors: {
    text: '#FFFFFF',
    background: '#050510',
    primary: '#EDCE61',
    red: '#FF002D',
    darkGray: '#B1B0B7',
  },
  text: {
    condensed: {
      fontFamily: 'GT America Condensed',
    },
  },
  buttons: {
    primary: {
      color: 'black',
      fontSize: 1,
      bg: 'primary',
      borderRadius: '2px',
      height: '4rem',
      padding: '1.375rem 2.5rem',
      cursor: 'pointer',
      ':hover': {
        filter: 'brightness(80%)',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        overflowWrap: 'break-word' as const,
      },
    },
  },
};
