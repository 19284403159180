/** @jsxImportSource theme-ui */

import React from 'react';
import { Button, Flex, Heading, Paragraph } from 'theme-ui';
import { Layout } from '../../components';
import { remCalc } from '../../styles/variables';
import getDashlaneUrl from '../../utils/getDashlaneUrl';

export const ThankYou = () => {
  function getTryUrl() {
    const isStaging = /staging|localhost/gi.test(window.location.href);

    return `https://${
      isStaging ? 'tests' : 'www'
    }.dashlane.com/business-password-manager/try?utm_source=BBR`;
  }

  return (
    <Layout>
      <Heading
        as={'h1'}
        sx={{
          textAlign: 'center',
          fontSize: [6, null, 7],
          lineHeight: [remCalc(45), null, remCalc(60)],
          margin: '1rem auto',
          maxWidth: remCalc(739),
          letterSpacing: '-0.02em',
        }}
      >
        Thanks for running a free scan with Business Breach Report!
      </Heading>
      <Paragraph
        sx={{
          maxWidth: remCalc(770),
          fontSize: remCalc(21),
          textAlign: 'center',
          margin: '3rem auto 0',
          lineHeight: '1.5rem',
          opacity: '0.85',
        }}
      >
        Your Business Breach Report request has been submitted.
      </Paragraph>
      <Paragraph
        sx={{
          padding: '2rem 0',
          maxWidth: remCalc(770),
          fontSize: remCalc(21),
          textAlign: 'center',
          margin: '0 auto',
          lineHeight: '1.5rem',
          opacity: '0.85',
        }}
      >
        Please check your email in the next few minutes to see your results.
        Employee passwords are behind most business breaches—which means
        securing your business starts with protecting your passwords. That’s
        where{' '}
        <a
          href={getDashlaneUrl()}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none', color: 'inherit' }}
        >
          Dashlane
        </a>{' '}
        comes in. Try the password manager that’s as easy to use as it is
        secure.
      </Paragraph>

      <Flex>
        <Button
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            fontSize: 2,
            fontWeight: 'bold',
            lineHeight: '1.5rem',
            height: remCalc(66),
            margin: '20px auto',
          }}
          as="a"
        >
          <a
            style={{
              textDecoration: 'none',
              color: 'black',
              fontWeight: 'bold',
            }}
            href={getTryUrl()}
          >
            Start securing your data
          </a>
        </Button>
      </Flex>
    </Layout>
  );
};
