import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';
import { theme } from './styles/theme';
import './styles/fonts.css';

import { Homepage, NotFound, Results } from './pages';
import reportWebVitals from './reportWebVitals';
import { loadGoogleTagManager } from './scripts/tracking';
import { ThankYou } from './pages/ThankYou';

const App = () => {
  useEffect(() => {
    loadGoogleTagManager();
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/results">
              <Results />
            </Route>
            <Route exact path="/thankyou">
              <ThankYou />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
