/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';
import Background from '../Background';
import { Footer } from '../Footer';
import { Header } from '../Header';

interface Props {
  showDisclaimer?: boolean;
}

export const Layout = ({
  children,
  showDisclaimer,
}: PropsWithChildren<unknown> & Props) => {
  return (
    <Background>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          height: '100%',
        }}
      >
        <Header />
        <main
          sx={{
            width: '100%',
            flex: '1 1 auto',
            padding: ['0rem 1rem', null, '0 13rem'],
          }}
        >
          {children}
        </main>
        <Footer showDisclaimer={showDisclaimer} />
      </div>
    </Background>
  );
};
