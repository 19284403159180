/** @jsxImportSource theme-ui */

import React from 'react';
import { Box, Grid, Heading, Paragraph } from 'theme-ui';
import { EmailCapture, Layout } from '../../components';
import { remCalc } from '../../styles/variables';

const boxHeadingStyles = {
  fontSize: 5,
  paddingBottom: '1rem',
  variant: 'text.condensed',
};

const boxParagraphStyles = {
  fontSize: 2,
  lineHeight: '1.5rem',
  opacity: '0.85',
};

export const Homepage = () => {
  // TODO: Add SEO Support, maybe React Helmet?
  return (
    <Layout>
      <Heading
        as={'div'}
        sx={{
          textTransform: 'uppercase',
          color: 'primary',
          textAlign: 'center',
          variant: 'text.condensed',
          fontSize: [2, null, 3],
          letterSpacing: '0.05em',
          fontWeight: 'bold',
        }}
      >
        Run a free vulnerability report
      </Heading>

      <Heading
        as={'h1'}
        sx={{
          textAlign: 'center',
          fontSize: [7, null, 8],
          lineHeight: remCalc(66),
          margin: '1rem auto',
          maxWidth: remCalc(739),
          letterSpacing: '-0.02em',
        }}
      >
        Has your business been breached?
      </Heading>
      <Paragraph
        sx={{
          maxWidth: remCalc(770),
          fontSize: remCalc(21),
          textAlign: 'center',
          margin: '3rem auto 0',
          lineHeight: '1.5rem',
          opacity: '0.85',
        }}
      >
        The first step in protecting important business data is knowing if any
        has been compromised in a breach.
      </Paragraph>
      <Paragraph
        sx={{
          padding: '2rem 0',
          maxWidth: remCalc(770),
          fontSize: remCalc(21),
          textAlign: 'center',
          margin: '0 auto',
          lineHeight: '1.5rem',
          opacity: '0.85',
        }}
      >
        See what data connected to your business domain has been exposed.
      </Paragraph>
      <EmailCapture />
      <Paragraph
        sx={{
          maxWidth: '45rem',
          fontSize: 0,
          margin: '0.5rem auto',
          lineHeight: remCalc(18),
          opacity: '0.85',
        }}
      >
        Dashlane will never sell your information.
      </Paragraph>
      <Heading
        as={'h2'}
        sx={{
          fontSize: 5,
          textAlign: 'center',
          color: 'primary',
          padding: '4rem 0',
        }}
      >
        Why should you run a scan?
      </Heading>
      <Grid
        sx={{
          textAlign: 'center',
          maxWidth: '64rem',
          margin: '0 auto',
        }}
        // width={[1, 1, 1]}
        columns={[1, null, 3]}
        gap={'1.5rem'}
      >
        <Box>
          <Heading as={'h3'} sx={boxHeadingStyles}>
            $4.24M
          </Heading>
          <Paragraph sx={boxParagraphStyles}>
            is the average cost of a data breach
          </Paragraph>
        </Box>
        <Box>
          <Heading as={'h3'} sx={boxHeadingStyles}>
            80%
          </Heading>
          <Paragraph sx={boxParagraphStyles}>
            of breaches are caused by weak, reused, or stolen employee passwords
          </Paragraph>
        </Box>
        <Box>
          <Heading as={'h3'} sx={boxHeadingStyles}>
            +20%
          </Heading>
          <Paragraph sx={boxParagraphStyles}>
            increase in data breaches following the rise in remote work
          </Paragraph>
        </Box>
      </Grid>
      <Heading
        as={'h2'}
        sx={{
          fontSize: 5,
          textAlign: 'center',
          color: 'primary',
          padding: '4rem 0 1rem',
        }}
      >
        How does it work?
      </Heading>
      <div sx={{ margin: '0 auto', maxWidth: '43rem' }}>
        <ol sx={{ fontSize: 2, lineHeight: '1.5rem', opacity: '0.85' }}>
          <li>Dashlane runs your business domain through a breach database</li>
          <li>
            You’ll get an email that verifies your domain and gives you access
            to your report
          </li>
          <li>
            You can view your free breach report—and see what steps to take next
          </li>
        </ol>
      </div>
      <Heading
        as={'h2'}
        sx={{
          fontSize: 5,
          textAlign: 'center',
          color: 'primary',
          padding: '3rem 0 2rem',
        }}
      >
        Want to skip right to securing your data?
      </Heading>
      <Paragraph
        sx={{
          textAlign: 'center',
          maxWidth: '55rem',
          margin: '0 auto 5rem',
          fontSize: 2,
          lineHeight: '1.5rem',
          opacity: '0.85',
        }}
      >
        With most data breaches caused by poor employee password habits, a
        password manager for your business is essential. There’s a reason
        20,000+ businesses trust Dashlane.&nbsp;
        <a
          href="https://www.dashlane.com/business/try?utm_source=BBR"
          sx={{ color: 'text' }}
        >
          Get started with your free trial
        </a>
        &nbsp;today.
      </Paragraph>
    </Layout>
  );
};
