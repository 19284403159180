/** @jsxImportSource theme-ui */
import React from 'react';
import { Heading } from 'theme-ui';

export const Header = () => {
  return (
    <header>
      <Heading
        as={'div'}
        sx={{
          variant: 'text.condensed',
          textTransform: 'uppercase',
          textAlign: 'center',
          fontSize: [3, null, 5],
          margin: '3.75rem 0',
          letterSpacing: '0.05em',
        }}
      >
        Business Breach Report
      </Heading>
    </header>
  );
};
