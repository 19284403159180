import { postRequestAPI, RequestFunctionOptions } from '@dashlane/apiconnect';

interface Props {
  apiName: string;
  apiMethod: string;
  payload: Record<string, string>;
}

interface PostDataResponse extends Response {
  data?: Record<string, unknown>;
  errors?: Record<string, unknown>;
}

const API_VERSION = 'v1';
const appAccessKey = 'RH9V3D5XVHN8JNYC2VTZWQ9AY1QJNTJY';
const appSecretKey =
  '9lxoY73mexmPHe/V++3KEqskAe+SQFodf7prGMj0jrDq9hBAuNvJItZlMMaTx+up';

const fetchImplementation = async (
  options: RequestFunctionOptions,
  cb: CallableFunction
) => {
  const { headers, json, url, method } = options;

  try {
    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(json),
    });
    return cb(null, await response.json());
  } catch (error) {
    return cb(error);
  }
};

export const postData = ({ apiName, apiMethod, payload }: Props) => {
  return new Promise<unknown>((resolve, reject) => {
    postRequestAPI(
      {
        requestFunction: fetchImplementation,
        authentication: {
          type: 'app',
          appAccessKey,
          appSecretKey,
        },
        path: `${API_VERSION}/${apiName}/${apiMethod}`,
        payload,
        // Passing in custom UA due to a bug in Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=571722
        userAgent: navigator.userAgent,
      },
      (error: string | undefined, response: PostDataResponse) => {
        if (error) {
          return reject(new Error(error));
        }
        if (response.errors) {
          return reject(response.errors[0]);
        }
        return resolve(response.data);
      }
    );
  });
};
