/** @jsxImportSource theme-ui */
import React from 'react';
import { Heading } from 'theme-ui';
import Background from '../../components/Background';

export const NotFound = () => (
  <Background>
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Heading
        as={'h1'}
        sx={{
          color: 'text',
          textAlign: 'center',
          fontSize: 6,
          lineHeight: '4rem',
          margin: 'auto',
        }}
      >
        Page not found
      </Heading>
    </div>
  </Background>
);
