/** @jsxImportSource theme-ui */
import React from 'react';
import { Flex, Paragraph } from 'theme-ui';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { remCalc } from '../../styles/variables';
import getDashlaneUrl from '../../utils/getDashlaneUrl';

interface Props {
  showDisclaimer?: boolean;
}

export const Footer = ({ showDisclaimer = true }: Props) => {
  return (
    <footer
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        p: 2,
        minWidth: 'calc(100vw - (100vw - 100%))',
        backgroundColor: 'black',
        padding: ['3rem 1rem', null, '3rem 13rem'],
        color: '#969696', // color updated to pass WCAG A11y Standards
        fontSize: 0,
        lineHeight: remCalc(18),
      }}
    >
      <Flex sx={{ width: '100%', justifyContent: 'center' }}>
        <Paragraph
          sx={{
            variant: 'text.condensed',
            letterSpacing: '0.1em',
            paddingRight: '0.75rem',
            paddingBottom: '1.75rem',
            fontWeight: 'bold',
            margin: `${remCalc(2)} 0 0`,
          }}
        >
          POWERED BY
        </Paragraph>
        <a href={getDashlaneUrl()} target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </Flex>
      {showDisclaimer && (
        <Paragraph sx={{ margin: '0 auto 1rem', maxWidth: remCalc(981) }}>
          By submitting your email, you consent to receive the latest
          cybersecurity news and exclusive offers from Dashlane. You can
          unsubscribe at any time. Please see Dashlane&#39;s Privacy Policy for
          detailed information about how Dashlane processes personal data.
        </Paragraph>
      )}
      <Paragraph sx={{ maxWidth: remCalc(981), margin: '0 auto' }}>
        © {new Date().getFullYear()} Dashlane SAS LLC. All rights reserved.
        Dashlane and the Dashlane logo are trademarks of Dashlane SAS,
        registered in the U.S. and other countries.
      </Paragraph>
    </footer>
  );
};
