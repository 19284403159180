/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Paragraph } from 'theme-ui';
import { useThemeUI } from '@theme-ui/core';
import { postData } from '../../api/dashlaneAPI-caller';
import { Layout, Lottie } from '../../components';
import Background from '../../components/Background';
import logo from '../../lottie/logo.json';
import { remCalc } from '../../styles/variables';

interface Content {
  amount: number;
  dataType: string;
}

interface BreachRecord {
  address: number;
  creditcard: number;
  email: number;
  geolocation: number;
  ip: number;
  password: number;
  personalinfo: number;
  phone: number;
  social: number;
  username: number;
}

interface BreachData {
  domain: string;
  nbBreaches: number;
  nbLeaks: number;
  nbRecordsPerType: BreachRecord;
}

const headLineStyles = {
  textAlign: 'center' as const,
  fontSize: [7, null, 8],
  lineHeight: remCalc(66),
  margin: '1rem auto',
  maxWidth: remCalc(739),
  letterSpacing: '-0.02em',
};

const openingCopyStyles = {
  padding: '2rem 0 4rem',
  maxWidth: remCalc(770),
  fontSize: remCalc(21),
  textAlign: 'center' as const,
  margin: '0 auto',
  lineHeight: '1.5rem',
  opacity: '0.85',
};

const headingStyles = {
  textAlign: 'center' as const,
  fontSize: 5,
  lineHeight: '2.5rem',
  margin: '1rem auto',
  maxWidth: remCalc(900),
  letterSpacing: '-0.02em',
  color: 'primary',
};

export const Results = () => {
  const { theme } = useThemeUI();
  const [loaded, isLoaded] = useState(false);
  const [errorState, isErrorState] = useState(false);
  const [data, setData] = useState<BreachData>();

  const address = data?.nbRecordsPerType.address ?? 0;
  const creditCard = data?.nbRecordsPerType.creditcard ?? 0;
  const email = data?.nbRecordsPerType.email ?? 0;
  const ip = data?.nbRecordsPerType.ip ?? 0;
  const geolocation = data?.nbRecordsPerType.geolocation ?? 0;
  const password = data?.nbRecordsPerType.password ?? 0;
  const personalInfo = data?.nbRecordsPerType.personalinfo ?? 0;
  const phone = data?.nbRecordsPerType.phone ?? 0;
  const social = data?.nbRecordsPerType.social ?? 0;
  const username = data?.nbRecordsPerType.username ?? 0;
  const ipLocation = ip + geolocation;
  const otherTypes = address + email + personalInfo + social + username;
  const dataContent: Content[] = [
    { amount: password, dataType: 'Passwords' },
    {
      amount: creditCard,
      dataType: 'Bank / Credit Cards',
    },
    { amount: ipLocation, dataType: 'IP Addresses / Locations' },
    { amount: phone, dataType: 'Phone Numbers' },
    { amount: otherTypes, dataType: 'Other Data Types*' },
  ];
  const numOfBreaches = data?.nbBreaches;
  const numOfLeaks = data?.nbLeaks;

  const setBorderRadius = (index: number) => {
    if (index === 0) {
      return '0.25rem 0  0 0.25rem';
    } else if (index === dataContent.length - 1) {
      return '0 0.25rem 0.25rem 0';
    } else {
      return '0';
    }
  };

  useEffect(() => {
    const tokenValue = location.search.split('token=')[1];
    postData({
      apiName: 'businessbreachreport',
      apiMethod: 'ComputeBusinessBreachReport',
      payload: {
        token: tokenValue,
      },
    }).then(
      (results) => {
        isLoaded(true);
        setData(results as BreachData);
      },
      (error) => {
        if (error) {
          isErrorState(true);
        }
      }
    );
  }, []);

  if (errorState) {
    return (
      <Background>
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Heading
            as={'h1'}
            sx={{
              textAlign: 'center',
              fontSize: 5,
              lineHeight: '4rem',
            }}
          >
            Oops! It looks like this link has expired.
          </Heading>
          <Paragraph
            sx={{
              textAlign: 'center',
              fontSize: 4,
              lineHeight: '2.5rem',
            }}
          >
            Please&nbsp;
            <a href="/" sx={{ fontWeight: 'bold', color: 'text' }}>
              run another Breach Report here.
            </a>
          </Paragraph>
        </div>
      </Background>
    );
  }
  if (loaded) {
    return (
      <Layout showDisclaimer={false}>
        <Heading
          as={'div'}
          sx={{
            textTransform: 'uppercase',
            color: 'primary',
            textAlign: 'center',
            variant: 'text.condensed',
            fontSize: [2, null, 3],
            letterSpacing: '0.05em',
            fontWeight: 'bold',
          }}
        >
          Your Breach Report
        </Heading>
        {numOfBreaches === 0 && numOfLeaks === 0 ? (
          <>
            <Heading as={'h1'} sx={headLineStyles}>
              {data?.domain} was not breached
            </Heading>
            <Paragraph sx={openingCopyStyles}>
              Good news! No exposed data was found connected to {data?.domain}.
            </Paragraph>
            <Heading as={'h2'} sx={headingStyles}>
              How do you prevent future breaches?
            </Heading>
          </>
        ) : (
          <>
            <Heading as={'h1'} sx={headLineStyles}>
              {data?.domain} was breached
            </Heading>
            <Paragraph sx={openingCopyStyles}>
              Below is a breakdown of the exposed data you should know about.
            </Paragraph>
            <Flex
              sx={{
                gridTemplateColumns: ['1fr', null, `repeat(5, 1fr)`],
                flexDirection: ['column', null, 'row'],
                maxWidth: '64rem',
                margin: '0 auto 5rem',
              }}
            >
              {dataContent.map((content, idx) => {
                const borderRadiusValue = setBorderRadius(idx);
                return (
                  <Box
                    key={content.dataType}
                    sx={{
                      border: `${remCalc(1)} solid ${theme.colors?.darkGray}`,
                      borderRadius: borderRadiusValue,
                      maxHeight: '11.5rem',
                      textAlign: 'center',
                      padding: '2rem 1rem',
                      width: ['100%', null, '12.8rem'],
                      margin: '0 auto',
                    }}
                  >
                    <Paragraph
                      sx={{ color: 'primary', fontSize: 5, fontWeight: 'bold' }}
                    >
                      {content.amount}
                    </Paragraph>
                    <Paragraph sx={{ fontSize: 2, lineHeight: '1.5rem' }}>
                      {content.dataType}
                    </Paragraph>
                  </Box>
                );
              })}
            </Flex>
            <Heading as={'h2'} sx={headingStyles}>
              What should you do next?
            </Heading>
          </>
        )}
        <Paragraph
          sx={{
            maxWidth: '64rem',
            fontSize: 2,
            textAlign: 'center',
            margin: '0 auto',
            lineHeight: '1.5rem',
            opacity: '0.85',
          }}
        >
          Employee passwords are behind most business breaches—which means
          securing your business starts with protecting your passwords. That’s
          where Dashlane comes in. Try the password manager that’s as easy to
          use as it is secure.
        </Paragraph>
        <Button
          sx={{
            display: 'block',
            margin: '2.5rem auto',
            padding: ['0.5rem 1rem', null, '1.25rem 2.5rem'],
            fontSize: 2,
            borderRadius: '0.25rem',
            fontWeight: 'bold',
            lineHeight: '1.5rem',
          }}
          onClick={() => {
            location.href =
              'https://www.dashlane.com/business/try?utm_source=BBR';
          }}
        >
          Start securing your data
        </Button>
        <Paragraph
          sx={{
            padding: '3rem 0 7rem',
            maxWidth: '64rem',
            fontSize: 0,
            textAlign: 'center',
            margin: '0 auto',
            lineHeight: remCalc(18),
            opacity: '0.85',
          }}
        >
          {numOfBreaches === 0 && numOfLeaks === 0
            ? ''
            : `* may include
          sensitive information — age, gender, languages,
          birthdates, etc.`}
        </Paragraph>
      </Layout>
    );
  } else {
    return (
      <Layout showDisclaimer={false}>
        <Lottie animationJson={logo} />
        <Paragraph sx={{ textAlign: 'center', fontSize: [0, null, 2] }}>
          Please give us a moment while we compile your results
        </Paragraph>
      </Layout>
    );
  }
};
