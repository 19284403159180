import React, { useState } from 'react';
import { Button, Input, Paragraph } from 'theme-ui';
import { useThemeUI } from '@theme-ui/core';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { postData } from '../../api/dashlaneAPI-caller';
import { remCalc } from '../../styles/variables';
import { useHistory } from 'react-router-dom';

const errorMessageStyles = {
  color: 'red',
  padding: '3rem 0',
  fontSize: 2,
  lineHeight: '1.5rem',
};

const backgroundStyles = {
  background: 'rgba(255, 255, 255, 0.2)',
};

export const EmailCapture = () => {
  const { theme } = useThemeUI();
  const [message, setMessage] = useState('');
  const [successState, setSuccessState] = useState(false);
  const { push } = useHistory();
  const validEmailError = 'Please enter a valid business email address';
  const EmailCaptureSchema = Yup.object().shape({
    email: Yup.string()
      .email(validEmailError)
      .required('Valid business email address required'),
  });

  const handleFocus = () => {
    setMessage('');
  };

  return !successState ? (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={EmailCaptureSchema}
      onSubmit={(values) => {
        postData({
          apiName: 'businessbreachreport',
          apiMethod: 'RegisterBusinessBreachReport',
          payload: {
            email: values.email,
          },
        }).then(
          () => {
            setSuccessState(true);
            push('/thankyou');
          },
          (error) => {
            setSuccessState(false);
            if (error.code === 'FREE_EMAIL_PROVIDER') {
              setMessage(validEmailError);
            } else if (error.code === 'USER_HAS_ALREADY_REQUESTED_REPORT') {
              setMessage(error.message);
            }
          }
        );
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <form
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexWrap: 'wrap',
          }}
          onSubmit={handleSubmit}
        >
          <Input
            sx={{
              flex: 1,
              padding: '1.25rem 1.5rem',
              maxWidth: '34rem',
              borderColor: 'primary',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: 'background',
              borderRightWidth: '0',
              '&::placeholder': {
                color: 'text',
                opacity: '0.85',
              },
              fontSize: 2,
              lineHeight: '1.5rem',
              ...backgroundStyles,
            }}
            placeholder="Enter your business email"
            onChange={handleChange}
            onFocus={() => handleFocus()}
            name="email"
            value={values.email || ''}
          />
          <Button
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              fontSize: 2,
              fontWeight: 'bold',
              lineHeight: '1.5rem',
              height: remCalc(66),
            }}
            type={'submit'}
          >
            Check now
          </Button>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              height: '0',
            }}
          >
            <ErrorMessage
              name="email"
              render={(msg) => (
                <Paragraph sx={errorMessageStyles}>{msg}</Paragraph>
              )}
            />
            <Paragraph sx={errorMessageStyles}>{message}</Paragraph>
          </div>
        </form>
      )}
    </Formik>
  ) : (
    <Paragraph
      sx={{
        maxWidth: '45rem',
        fontSize: 2,
        border: `${remCalc(1)} solid ${theme.colors?.primary}`,
        borderRadius: '0.25rem',
        padding: '1.25rem 1.5rem',
        margin: '0 auto',
        lineHeight: '1.5rem',
        opacity: '0.85',
        ...backgroundStyles,
      }}
    >
      Thanks! Please check your email for your results.
    </Paragraph>
  );
};
