/** @jsxImportSource theme-ui */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import UAParser from 'ua-parser-js';

interface LottieProps {
  animationJson: unknown;
  mobileAnimationJson?: unknown;
  loopMode?: boolean | number;
}

export const Lottie = ({
  animationJson,
  mobileAnimationJson,
  loopMode = true,
}: LottieProps) => {
  const lottieContainer = useRef<HTMLDivElement>(null);
  const lottieContainerMobile = useRef<HTMLDivElement>(null);
  const UAParsedDevice = new UAParser().getDevice().type;

  useEffect(() => {
    if (lottieContainer.current) {
      lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: loopMode,
        autoplay: true,
        animationData: animationJson,
      });
    }
    if (lottieContainerMobile.current) {
      lottie.loadAnimation({
        container: lottieContainerMobile.current,
        renderer: 'svg',
        loop: loopMode,
        autoplay: true,
        animationData: mobileAnimationJson,
      });
    }
  }, [animationJson, loopMode, mobileAnimationJson]);

  if (mobileAnimationJson && UAParsedDevice === 'mobile') {
    return <div ref={lottieContainerMobile} />;
  } else {
    return (
      <div
        sx={{ width: '100%', height: ['auto', null, '25rem'] }}
        ref={lottieContainer}
      />
    );
  }
};
